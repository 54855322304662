.dashboard {
  flex-grow: 1;
  padding: 70px 0 20px 0;
  min-height: calc(100vh - 80px);
  background-color: #f5f5f5;

  .container {
    margin: 1rem auto 0;
    padding: 0;
  }

  .offerSelect {
    margin: 3rem 0 1rem;
  }
  .announcement {
    ul {
      text-align: left;
    }
  }
}

.verticalCard {
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 150px;
  transition: all 0.3s ease;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Black with 0.5 opacity */
    transition: background-color 0.3s ease;
  }

  &.MuiAvatar-root {
    transition: all 0.3s;
    border: 6px solid transparent;
  }

  &:hover,
  &.active {
    &:after {
      background-color: rgba(0, 0, 0, 0); /* Transparent on hover */
    }
    &.MuiAvatar-root {
      border: 6px solid rgba(0, 0, 0, 0.3);
    }
    &.MuiTypography-root {
      text-decoration: underline;
    }
  }
  p {
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    z-index: 1;
    text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.6);
  }
}
